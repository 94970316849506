import React, { useState, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { time, helpers, api } from "services";

import { CustomPaginate, Fields, LoadMoreVisible, PhoneCopy } from "components";
import EntityContainer from "modules/entity/containers";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Field } from "formik";
import { useLocation } from "react-router-dom";

const Patients = ({
	// tableData,
	isCheckBox,
	// tableHead,
	tableClassName = "",
	handleChange,
	filter = {},
	isPage = false,
	chosenCustomers = [],
	daily = false,
	sorId = true,
	date = true
}) => {
	const history = useHistory();
	const modalTrigger = useSelector(state => get(state, "modal.refetch"));
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(20);

	const handleClick = id => {
		history.push(`/customer/view/${id}`);
	};
	const [appendData, setAppendData] = useState(true);

	function loadMore(e, meta, isFetched) {
		setAppendData(true);
		const items = document.getElementsByClassName("content-table");
		if (items.length > 0) {
			const last = items[items.length - 1];
			const is_bottom =
				e.target.getBoundingClientRect().bottom + 50 >=
				last.getBoundingClientRect().bottom;
			if (
				is_bottom &&
				get(meta, "currentPage") < get(meta, "pageCount") &&
				isFetched
			) {
				setPage(page + 1);
			}
		}
	}

	useEffect(() => {
		setPage(1);
		setAppendData(false);
	}, [modalTrigger]);
	return (
		<>
			<EntityContainer.All
				entity="customerList"
				name="customerList"
				url={`/customer${daily ? "/daily" : ""}`}
				params={{
					page:page,
					limit: perPage,
					sort: sorId ? "-id" : "",
					include: "district.region,region,source",
					filter: {
						...filter
					},
					modalTrigger
				}}
				metaKey={data => {
					return get(data, "meta");
				}}
				appendData={appendData}>
				{({ items, meta, isFetched }) => {
					items.sort((a, b) => b.id - a.id);
					return (
						<>
							<div
								className="table-container"
								// onScroll={e => loadMore(e, meta, isFetched)}
							>
								<div className="content-table-parent">
									<table
										className={`content-table ${tableClassName}`}>
										<thead>
											<tr>
												{isCheckBox && <th />}
												<th>ID</th>
												<th>Имя</th>
												<th>Адрес</th>
												<th>Источник</th>
												<th>Телефон Номер</th>
												{date && <th>Дата</th>}
											</tr>
										</thead>

										<tbody className="tbody">
											{items &&
												items.map((item, i) => {
													const {
														id,
														region,
														district,
														source,
														phone,
														created_at
													} = item;
													return (
														<tr
															key={id}
															onClick={() =>
																handleClick(id)
															}
															className="patient-tr">
															{isCheckBox && (
																<td>
																	<Fields.CheckBox
																		onChange={(
																			event,
																			prevValue
																		) =>
																			handleChange(
																				item,
																				prevValue
																			)
																		}
																		defaultChecked={
																			chosenCustomers.findIndex(
																				item =>
																					item.id ===
																					id
																			) >
																			-1
																		}
																	/>
																</td>
															)}
															<td>{id}</td>
															<td>
																<div className={"d-flex flex-column"}>
																	<span>
																		{get(
																			item,
																			"name",
																			""
																		)}
																	</span>
																	<span style={{fontSize: "12px", color: "rgba(0, 0, 0, 0.5)", fontWeight: "normal"}}>
																		{get(
																			item,
																			"gender",
																			1
																		) == 1 ? "Мужчина" : "Женщина"}
																	</span>
																</div>
															</td>
															<td>
																{`${get(
																	region,
																	"name",
																	""
																)} ${get(
																	district,
																	"name",
																	""
																)}`}
															</td>
															<td>
																{get(
																	source,
																	"name.uz",
																	""
																)}
															</td>
															<td className="white-space_no-wrap">
																<PhoneCopy
																	phone={helpers.formatPhoneView(
																		phone,
																		false
																	)}
																/>
															</td>
															{date && (
																<td>
																	{time.to(
																		created_at,
																		"DD.MM.YYYY"
																	)}
																</td>
															)}
														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
								{/*{isFetched &&*/}
								{/*	isPage &&*/}
								{/*	get(meta, "currentPage") <*/}
								{/*	get(meta, "pageCount") && (*/}
								{/*		<LoadMoreVisible*/}
								{/*			setPage={() => {*/}
								{/*				console.log("SCROLL");*/}
								{/*				setAppendData(true);*/}
								{/*				setPage(page + 1);*/}
								{/*			}}*/}
								{/*		/>*/}
								{/*	)}*/}
							</div>
							<CustomPaginate
								meta={meta}
								isFetched={isFetched}
								setAppendData={setAppendData}
								page={page}
								setPage={setPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</>
					);
				}}
			</EntityContainer.All>
		</>
	);
};

Patients.defaultProps = {
	tableData: [],
	tableHead: []
};

Patients.propTypes = {
	tableData: PropTypes.array.isRequired
};

export default Patients;
