import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash';

import "./styles.scss"
import { helpers } from 'services';

const Line = ({ title, categories, values }) => {
    const [series, setSeries] = useState(false)
    const id = useMemo(() => uniqueId(), [])

    useEffect(() => {
        setSeries(values)
    });

    const state = {

        series: [],
        options: {
            xaxis: {
                categories: categories
            },
            chart: {
                type: 'area',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                // events: {
                //     selection: function (chart, e) {
                //         console.log(new Date(e.xaxis.min))
                //     }
                // },
            },
            tooltip: {
                theme: 'dark',
            },
            colors: ['#0E59E5', '#11C077', '#FF3737'],

            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'monotoneCubic'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left'
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    };

    function animateNumber(i) {
        const element = document.getElementById(`number-${id}`)
        const start = Number(element.innerHTML)
        helpers.animateValue(element, start, values[i], 300)
    }



    return (
        <div className="line-chart bg_white">
            <div className="line-chart-title">
                {title}
            </div>
            <div className="chart-wrapper">
                <ReactApexChart options={state.options} series={series || state.series} type="area" width='100%'
                                height={300} />
            </div>
        </div>
    )
}

Line.propTypes = {
    categories: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired
}

export default Line