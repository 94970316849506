import { useEffect, useState } from "react";
import axios from "axios";
import get from "lodash/get";
import { env } from "./../../../env";
import { helpers, time } from "services";
import { genderOptions } from "services/helpers";

const FormFields = ({ data, formData, callType }) => {
	const LoadDefaultCounty = () => {
		const [country, setCountry] = useState([]);
		const [loading, setLoading] = useState(true);
		const [error, setError] = useState(null);

		useEffect(() => {
			axios
				.get(env.REACT_APP_API_ROOT + "country?id=236")
				.then(response => {
					setCountry(response.data.data[0]);
					setLoading(false);
				})
				.catch(error => {
					setError(error);
					setLoading(false);
				});
		}, []);

		return country;
	};

	const LoadDefaultRegion = () => {
		const [region, setRegion] = useState([]);
		const [loading, setLoading] = useState(true);
		const [error, setError] = useState(null);

		useEffect(() => {
			axios
				.get(env.REACT_APP_API_ROOT + "region")
				.then(response => {
					setRegion(response.data.data[13]);
					setLoading(false);
				})
				.catch(error => {
					setError(error);
					setLoading(false);
				});
		}, []);
		return region;
	};

	const LoadDefaultCategoryId = () => {
		const [child, setChild] = useState([]);
		const [loading, setLoading] = useState(true);
		const [error, setError] = useState(null);

		useEffect(() => {
			axios
				.get(
					env.REACT_APP_API_ROOT +
						"category/slug/call-category/child"
				)
				.then(response => {
					setChild(response.data.data[0]);
					setLoading(false);
				})
				.catch(error => {
					setError(error);
					setLoading(false);
				});
		}, []);
		return child;
	};

	const validations = [
		{
			name: "queue_number",
			type: "object",
			value: get(formData, "customer.queue_number.full_name", "Оператор не выбран"),
			onSubmitValue: value => value
		},
		{
			name: "type",
			type: "number",
			value: get(formData, "type", 1),
			onSubmitValue: value => value
		},
		{
			name: "name",
			type: "string",
			value: get(formData, "name", ""),
			required: true
		},
		{
			name: "gender",
			value: genderOptions.find(
				item =>
					item.value ===
					get(formData, "gender", genderOptions[0].value)
			),
			onSubmitValue: option => option.value
		},
		{
			name: "birthdate",
			type: "string",
			value: time.to(get(formData, "birthdate"), "DD/MM/YYYY"),
			onSubmitValue: value => {
				return time.customTimeStamp(value);
			}
		},
		{
			name: "country_id",
			type: "object",
			value: get(formData, "country", LoadDefaultCounty()),
			onSubmitValue: value => get(value, "id"),
			required: true
		},
		{
			name: "region_id",
			type: "object",
			value: get(formData, "region", LoadDefaultRegion()),
			onSubmitValue: value => get(value, "id")
		},
		{
			name: "district_id",
			type: "object",
			value: get(formData, "district", 1),
			onSubmitValue: value => get(value, "id")
		},
		{
			name: "address",
			type: "string",
			value: get(formData, "address"),
			onSubmitValue: value => value
		},
		{
			name: "category_id",
			type: "object",
			value: get(formData, "category", LoadDefaultCategoryId()),
			onSubmitValue: value => get(value, "id"),
			required: true
		},
		{
			name: "source_id",
			type: "object",
			value: get(formData, "source", 1),
			onSubmitValue: value => get(value, "id", 1)
		},
		{
			name: "sub_source_id",
			type: "object",
			value: get(formData, "sub_source", 1),
			onSubmitValue: value => get(value, "id", "")
		},
		{
			name: "service_parent",
			type: "object",
			value: get(formData, "services[0].category.parent", ""),
			onSubmitValue: value => value
		},
		{
			name: "service_child",
			type: "object",
			value: get(formData, "services[0].category", ""),
			onSubmitValue: value => value
		},
		{
			name: "service_ids",
			type: "array",
			value: get(formData, "services", []),
			onSubmitValue: value => value
		},
		{
			name: "phone",
			type: "string",
			required: true,
			value: helpers.formatPhoneView(
				get(data, "phone", "") || get(formData, "phone")
			),
			onSubmitValue: value => helpers.formatPhoneApi(value)
		},
		{
			name: "additional_number",
			type: "string",
			value: get(
				formData,
				"customer.additional_number.telegram_number",
				""
			),
			onSubmitValue: value => {
				return { telegram_number: value };
			}
		},
		{
			name: "comment",
			type: "string",
			value: get(formData, "comment", ""),
			onSubmitValue: value => value
		},
		{
			name: "activity_category",
			type: "string",
			required: callType === 2,
			value: get(formData, "customer.activity_category", "")
		},
		{
			name: "contact_person_name",
			type: "string",
			value: get(formData, "customer.contact_person_name", ""),
			required: callType === 2
		},
		{
			name: "contact_person_position",
			type: "object",
			value: get(formData, "contact_person_position"),
			onSubmitValue: value => get(value, "contact_person_position", "")
		},
		{
			name: "call_history_id",
			value:
				get(data, "call_history_id", get(data, "id", null)) ||
				get(formData, "call_history_id", null)
			// onSubmitValue: value => {
			// 	return (
			// 		get(data, "call_history_id", null) ||
			// 		get(formData, "call_history_id", null)
			// 	);
			// }
		},
		{
			name: "customer_id",
			value: get(data, "customer_id", null) || get(formData, "id", null)
			// onSubmitValue: value => {
			// 	get(data, "customer_id", null) ||
			// 		get(formData, "customer_id", null);
			// }
		}
	];

	if (get(data, "isManual")) {
		validations.push({
			name: "connected_number",
			required: true,
			value: {
				number: get(data, "connected_number")
			},
			onSubmitValue: value => get(value, "number")
		});
	}

	// console.log(data , '\n',formData, '\n',validations)
	return validations;
};

export default FormFields;
