import React from "react";

import { constants } from "services";

import { ReactComponent as OutboundCallOn } from "assets/images/svg/call-inbound.svg";
import { ReactComponent as IncomeCallOn } from "assets/images/svg/call-outbound.svg";
import { ReactComponent as OutboundCallMissed } from "assets/images/svg/fi_phone-missed.svg";
import { ReactComponent as CustomerOff } from "assets/images/svg/fi_phone-off.svg";
import { ReactComponent as OutboundCallNoAnswer } from "assets/images/svg/call-outbound-no-answer.svg";

export const CallStatus = ({ status }) => {
	return (
		<>
			{status === constants.STATUS_END && <><IncomeCallOn /> <span>Входящий</span></>}

			{status === constants.STATUS_SELF && <><OutboundCallOn /> <span>Исходящий</span></>}

			{status === constants.STATUS_NO_ANSWER && <><OutboundCallMissed /> <span>Входящий пропущенный</span></>}

			{status === constants.STATUS_CLIENT_BUSY && (
				<><OutboundCallNoAnswer /> <span>Исходящий клиент занят</span></>
			)}

			{status === constants.STATUS_CLIENT_NO_ANSWER && <><CustomerOff /> <span>Исходящий без ответа</span></>}
		</>
	);
};
