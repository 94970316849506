import React from "react";
import Modal from "./Modal";
import useMicrophoneCheck from "../../hooks/useMicrophoneCheck";
import { toast } from "react-toastify";

function MicrophoneCheck() {
	const {
		hasMicrophone,
		userPermittedMicrophone,
		showModal,
		handlePermission,
		handleCloseMicrophone,
		handleOpenMicrophone
	} = useMicrophoneCheck();

	window.checkPermission = async () => {
		if (hasMicrophone && userPermittedMicrophone) return true;
		handleOpenMicrophone();
		return false;
	};
	const onHandlePermission = async () => {
		const result = await handlePermission();
		if (!result) {
			toast.warn(
				"Вы не разрешили доступ к микрофону или заблокировали для сайта",
				{
					autoClose: 5000
				}
			);
		}
	};
	return (
		<Modal isOpen={showModal} toggle={() => {}} size={700}>
			<div
				style={{
					padding: 20
				}}
				className="modal-wrapper">
				<div className="modal-header">
					<h3 className="modal-title">Предупреждение!</h3>
					<button
						className="modal-close"
						onClick={handleCloseMicrophone}>
						{" "}
					</button>
				</div>
				<div
					style={{
						minHeight: 100,
						padding: "1rem"
					}}
					className="modal-body">
					<div className="modal-body__content">
						{!hasMicrophone && "У вас нет подключенных гарнитур"}
						{hasMicrophone &&
							!userPermittedMicrophone &&
							"Вам необходимо разрешить доступ к микрофону, чтобы совершать звонки."}
					</div>
				</div>
				<div
					style={{
						justifyContent: "flex-end"
					}}
					className="modal-footer">
					{!hasMicrophone && (
						<button
							className="btn btn-blue"
							onClick={handleCloseMicrophone}>
							Понятно
						</button>
					)}
					{hasMicrophone && !userPermittedMicrophone && (
						<>
							<button
								className="btn btn-blue"
								onClick={onHandlePermission}>
								Разрешить
							</button>
							<button
								style={{
									boxShadow: "unset"
								}}
								className="modal-btn red"
								onClick={handleCloseMicrophone}>
								Отмена
							</button>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
}

export default MicrophoneCheck;
