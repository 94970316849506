import React from "react";
import { storage } from "services";

function ColumnsCheckerModal({ columns, setColumns }) {
	//
	function change(value, e) {
		const checked = e.target.checked;

		if (Array.isArray(columns)) {
			let newArr = [];
			if (checked) {
				newArr = [...columns, value];
			} else {
				newArr = columns.filter(c => c !== value);
			}

			setColumns(newArr);
			storage.set("columns", JSON.stringify(newArr));
		} else {
			if (checked) {
				setColumns([value]);
				storage.set("columns", `[${value}]`);
			}
		}

	}

	const values = [
		"ID",
		"Имя",
		"Адрес",
		"Оператор",
		"Номер организации",
		"Телефон Номер",
		"Канал рекламы",
		"Причина",
		"Комментарий",
		"Дата",
		"Длительность",
		"Звонок",
	];

	return (
		<div className="columns_modal">
			{values.map(value => {
				return (
					<label>
						<input
							type="checkbox"
							onChange={e => change(value, e)}
							defaultChecked={columns.find(c => c === value)}
						/>
						<p>{value}</p>
					</label>
				);
			})}
		</div>
	);
}

export default ColumnsCheckerModal;
