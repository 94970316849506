import React, { useState } from "react";
// import EntityForm from "modules/entity/forms";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import service from "../../../../assets/images/svg/service.svg";
import organizationNum from "../../../../assets/images/svg/organizationNum.svg";
import source from "../../../../assets/images/svg/source.svg";
import callHistory from "../../../../assets/images/svg/callHistory.svg";
import statistic from "../../../../assets/images/svg/statistic.svg";
import operators from "../../../../assets/images/svg/operators.svg";
import plugins from "../../../../assets/images/svg/plugins.svg";
import { Spinner } from "../../../index";
import Modal from "../../Modal";
import { useModal } from "../../../../hooks/useModal";
import { storage } from "../../../../services";
import actions from "../../../../store/actions";
import { useDispatch } from "react-redux";

const ChangeThemeModal = ({isOpen, handleClose}) => {

	const [loading, setLoading] = useState(false);

	const themes = [
		'air',
		'amethyst-inspiration',
		'andromeda-galaxy',
		'architecture',
		'astronomical-watercolor',
		'atmosphere',
		'autumn-forest',
		'beach',
		'bird',
		'camouflage',
		'canyon',
		'christmas-ball',
		'christmas-gift',
		'christmas-snow',
		'city',
		'cloud-sea',
		'clouds',
		'coastal-dunes',
		'coloured-feathers',
		'coloured-paper',
		'cosmic-dreams',
		'cosmic-string',
		'countryside',
		'dark-silk',
		'dawn-harmony',
		'delicate-silk',
		'desert',
		'dew',
		'easter-eggs',
		'fabric',
		'feathers',
		'flamingo',
		'flower',
		'flower-and-leafs',
		'flowers',
		'foggy-horizon',
		'freshness',
		'fur',
		'galactic-dream',
		'galactic-harmony',
		'grass',
		'grass-2',
		'grass-ears',
		'greenfield',
		'halloween',
		'highness',
		'hills',
		'horses',
		'houses',
		'ice',
		'jack-o-lantern',
		'jupiter',
		'lake',
		'lava',
		'leafs',
		'light-fabric',
		'lion-cubs',
		'lotus',
		'magic-spheres',
		'meditation',
		'metro',
		'milky-way',
		'morning',
		'mountain',
		'mountain-air',
		'mountains',
		'mountains-2',
		'mountains-3',
		'mysterious-vega',
		'neptune',
		'new-years-room',
		'northern-lights',
		'offices',
		'orbital-symphony',
		'orion',
		'paradise',
		'pattern-tulips',
		'perspective',
		'pink-fencer',
		'plants',
		'pluto',
		'raspberry-daiquiri',
		'red-field',
		'saturn',
		'scooter',
		'sea',
		'sea-stones',
		'sea-sunset',
		'seashells',
		'shining',
		'skyscraper',
		'slope',
		'snow-village',
		'starfish',
		'stars',
		'steel-wall',
		'sunset',
		'sunset-magic',
		'table',
		'travel',
		'tree',
		'trees',
		'tropical-sunset',
		'tulips',
		'valentines-hearts',
		'valley',
		'vibration',
		'village',
		'wall',
		'wind',
		'window',
		'winter-forest',
		'winter-night',
		'wooden-letters',
	];

	const dispatch = useDispatch();

	const handleTheme = (selectedTheme) => {

		storage.set(
			"theme",
			selectedTheme
			// JSON.stringify([
			// 	"ID",
			// 	"Имя",
			// ])
		);

		const themeBg = document.querySelector(".theme-background-image");
		themeBg.style.background = "url(" + require("assets/images/themes/" + selectedTheme + ".jpg") + ")";

	}

	return (
		<Modal isOpen={isOpen} toggle={() => {}} size={1190}>
			{loading ? (
				<Spinner id="modal-loader" />
			) : (
		<div className="modal-wrapper">
			<div>
				<div className="pluginsModall">
					<p className="settingsModall__subtext" style={{"font-size": "14px", "margin-bottom": "0"}}>
						Темы оформления
					</p>
					<div className={"row theme-list"}>
						{
							themes.map((theme, index) => {
								return (
									<div className={"col-3"} key={index}>
										<div className={"theme-item"} onClick={() => {handleTheme(theme)}}>
											<div>
												<img src={require("assets/images/themes/" + theme + ".jpg")} alt={""} />
											</div>
										</div>
									</div>
								);
							})
						}
					</div>
					<button
						type="reset"
						className="settingsModall__close"
						data-bs-dismiss="modal"
						onClick={() => {
							handleClose && handleClose();
							// toast.error("Действие отменено!")
						}}>
						<img src={require("assets/images/svg/close.svg")} width={12} />
					</button>
				</div>
			</div>
		</div>)}
				</Modal>
	);
};

export default ChangeThemeModal;
