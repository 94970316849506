import React, { useState, useRef } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import EntityContainer from "modules/entity/forms";
import { api, constants, queryBuilder } from "services";
import get from "lodash/get";
import axios from "axios";
import { env } from "./../../../env";

import { ReactComponent as AddFile } from "assets/images/svg/addFile.svg";
import { ReactComponent as ClockIcon } from "assets/images/svg/clock.svg";
import { ReactComponent as SendIcon } from "assets/images/svg/send.svg";

import { NotificationModal } from "pages/Task/components/NotificationModal";
import { useOutsideClick } from "hooks/useOutsideClick";

import {
	Editor,
	EditorProvider,
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnRedo,
	BtnStrikeThrough,
	BtnStyles,
	BtnUnderline,
	BtnUndo,
	Separator,
	Toolbar
} from "react-simple-wysiwyg";

export const ApplicationViewSendMessage = ({ taskInfo, onSuccess }) => {
	const { id } = useParams();
	const user = useSelector(state => state.auth.user);
	const [editorValue, setEditorValue] = useState("");

	const {
		ref,
		isVisible,
		handleCloseMenu,
		handleToggleVisibility
	} = useOutsideClick();

	const fileInputRef = useRef(null);
	const [fileId, setFileId] = useState(null);

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click(); // Trigger the file input's click event
		}
	};

	const handleFileChange = async event => {
		const file = event.target.files[0];
		if (file) {
			try {
				// Replace the below URL with your actual file upload endpoint
				const formData = new FormData();
				formData.append("files[0]", file);

				const response = await axios.post(
					env.REACT_APP_API_ROOT + "file",
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}
				);

				const fileId = response.data[0].data.id; // Assuming the ID is returned as 'id'
				setFileId(fileId);

				if (response.status === 200) {
					api.request.post(
						queryBuilder(`/task-log`, ),
						{
							task_id: id,
							type: 11,
							file_id: fileId
						}
					)
				}

				// Notify parent component or handle success action
				// if (onFileUploadSuccess) {
				// 	onFileUploadSuccess(fileId);
				// }

				console.log("File uploaded successfully with ID:", fileId);
			} catch (error) {
				console.error("File upload failed:", error);
			}
		}
	};

	return (
		<EntityContainer.Main
			entity="task-log"
			name="task-log/modify"
			url="/task-log"
			method="post"
			primaryKey="id"
			onSuccess={(_, __, resetForm) => {
				resetForm();
				setEditorValue("");
				onSuccess();
			}}
			fields={[
				{
					name: "task_id",
					value: get(taskInfo, "id")
				},
				{
					name: "message",
					value: editorValue,
					required: true
				},
				{
					name: "type",
					value: constants.TASK_TYPE_CHAT_MESSAGE
				},
				{
					name: "description",
					value: "test-description"
				},
				{
					name: "operator",
					value: user.full_name
				}
			]}>
			{({ setFieldValue, values, handleSubmit }) => (
				<div className="app-view__content-area">
					<EditorProvider>
						<Editor
							value={values.message}
							onChange={event =>
								setFieldValue("message", event.target.value)
							}
							onKeyDown={event => {
								if (
									event.keyCode === constants.ESC_KEY_CODE &&
									!event.shiftKey
								) {
									event.preventDefault();
									handleSubmit();
								}
							}}>
							<Toolbar>
								<BtnBold />
								<BtnItalic />
								<BtnUnderline />
								<Separator />
								<BtnLink />
								<Separator />
								<BtnUndo />
								<BtnRedo />
								<Separator />
								<BtnNumberedList />
								<BtnBulletList />
								<BtnClearFormatting />

								<Separator />
								<BtnStrikeThrough />
								<Separator />
								<BtnStyles />
							</Toolbar>
						</Editor>
					</EditorProvider>

					<div className="app-view__buttons">
						<button
							type="file"
							onClick={handleButtonClick}
							className="modal-btn blue file-btn">
							<AddFile />
							Прикрепить файл
							<input
								type="file"
								ref={fileInputRef}
								style={{ display: "none" }} // Hide the file input
								onChange={handleFileChange}
							/>
						</button>

						<div ref={ref}>
							<NotificationModal
								isOpen={isVisible}
								handleClose={handleCloseMenu}
								taskId={id}
								customerId={get(taskInfo, "customer.id")}
							/>

							<button
								className="add-notification modal-btn blue"
								onClick={handleToggleVisibility}>
								<ClockIcon /> Добавить напоминание
							</button>
						</div>

						<button
							type="submit"
							className="modal-btn blue submit-btn"
							onClick={handleSubmit}>
							<SendIcon />
							Оставить комментарий
						</button>
					</div>
				</div>
			)}
		</EntityContainer.Main>
	);
};
