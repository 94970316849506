import React from "react";
import DateP from "react-datepicker";

import "../RangeDatePicker/styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import DateTime  from 'react-datetime';

const NewDateTimePicker = ({ setDate, date, ...props }) => {
	return (
		<div className="date-picker-wrapper">
			<DateTime />
		</div>
	);
};


export default NewDateTimePicker;
