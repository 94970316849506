import React from "react";
import { toast } from "react-toastify";
import { Field } from "formik";
import get from "lodash/get";

import EntityForm from "modules/entity/forms";
import { Fields, Modal } from "components";

import { ReactComponent as ClockIcon } from "assets/images/svg/clock.svg";
import DatePicker from "components/Fields/DatePicker/DatePicker";
import { constants, time } from "services";

export const TaskModal = ({
	isOpen,
	isUpdate,
	handleClose,
	formData,
	boardId,
	taskSort
}) => {
	return (
		<Modal
			isOpen={isOpen}
			size={700}
			toggle={() => {}}
			customClass="operator-phone modal-overflow">
			<div className="modal-wrapper">
				<div className="p_25">
					<EntityForm.Main
						entity="board"
						name="board/task"
						url={isUpdate ? `/task/${isUpdate}` : "/task"}
						method={isUpdate ? "put" : "post"}
						fields={[
							{
								name: "title",
								type: "string",
								value: get(formData, "title", ""),
								required: true
							},
							{
								name: "description",
								type: "string",
								value: get(formData, "description", "")
							},
							{
								name: "assigned_id",
								type: "object",
								value: get(formData, "assigned"),
								onSubmitValue: value => get(value, "id")
							},
							{
								name: "customer_id",
								type: "object",
								value: get(formData, "customer"),
								onSubmitValue: value => get(value, "id")
							},
							{
								name: "alert_at",
								type: "string",
								onSubmitValue: value => {
									return time.toTimestamp(value);
								}
							},
							{
								name: "action",
								value: constants.taskActionTypes.find(
									item => item.id === get(formData, "action")
								),
								type: "object",
								onSubmitValue: value => get(value, "id")
							},
							{
								name: "board_id",
								value: boardId
							},
							{
								name: "status",
								value: get(formData, "status", 1)
							}
							// {
							// 	name: "sort",
							// 	value: {taskSort}
							// }
						]}
						className="row g-3"
						onSuccess={() => {
							handleClose();
							toast.success("Успешно обновлен!", {
								autoClose: 5000
							});
						}}
						onError={() =>
							toast.error("Что то пошло не так", {
								autoClose: 5000
							})
						}>
						{({ values, setFieldValue }) => (
							<>
								<div className="col-12">
									<label className="d-block">
										<Field
											name="customer_id"
											component={Fields.AsyncSelect}
											className="select_xsm"
											size="large"
											placeholder="Клиент"
											isSearchable={true}
											optionLabel="name"
											onChange={option => {
												setFieldValue(
													"title",
													get(option, "name")
												);
											}}
											optionValue="id"
											loadOptionsUrl="/customer"
											loadOptionsParams={search => ({
												filter: { name: search }
											})}
										/>
									</label>
								</div>

								<div className="col-12">
									<div className="d-block task__description-box">
										<span className="task__modal-comment">
											Комментарий
										</span>

										<textarea
											value={values.description}
											name=""
											style={{
												height: "50px",
												margin: "10px 0"
											}}
											onChange={event =>
												setFieldValue(
													"description",
													event.target.value
												)
											}
										/>

										<div className="d-flex align-items-lg-center">
											<ClockIcon className="mr_10" />

											<div className="secondary-control d-flex justify-content-center mr_10">
												<DatePicker
													date={values.alert_at}
													placeholderText="Дата напоминания"
													enable={true}
													className="min-w-140"
													onKeyDown={event => {
														event.preventDefault();
													}}
													setDate={value =>
														setFieldValue(
															"alert_at",
															value
														)
													}
												/>
											</div>

											<div className="secondary-select mr_10">
												<Field
													name="assigned_id"
													component={
														Fields.AsyncSelect
													}
													size="large"
													placeholder="Кому"
													isSearchable={true}
													optionLabel="full_name"
													optionValue="id"
													loadOptionsUrl="/user/operators"
													loadOptionsParams={search => ({
														filter: { name: search }
													})}
												/>
											</div>

											<div className="secondary-select no-border">
												<Field
													name="action"
													component={
														Fields.FormikSelect
													}
													className={{
														classNamePrefix:
															"modal-select"
													}}
													options={
														constants.taskActionTypes
													}
													size="large"
													placeholder="причина"
													optionLabel="label"
													optionValue="id"
												/>
											</div>
										</div>

										<div className="d-flex justify-content-start mt_20">
											<button className="task__create-btn task-btn">
												Поставить
											</button>
											<button
												className="task__cancel-btn task-btn"
												type="reset"
												onClick={handleClose}>
												Отменить
											</button>
										</div>
									</div>
								</div>
							</>
						)}
					</EntityForm.Main>
				</div>
			</div>
		</Modal>
	);
};
