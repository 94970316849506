import React, { useEffect, useRef, useState } from "react";

import { useLongPress } from "hooks/useLongPress";
import { useOutsideClick } from "hooks/useOutsideClick";
import { SipService } from "services";
import { useSelector } from "react-redux";
import { get, set } from "lodash";

import ForwardCall from "./ForwardCall";
import useDisclosure from "../../hooks/useDisclosure";
import useTimeTracker from "../../hooks/useTimeTracker";
import { Rnd } from "react-rnd";

const phoneChars = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"*",
	"0",
	"#"
	// "",
	// "",
	// "C"
];

export const CallWindow = React.forwardRef(() => {
	const inputRef = useRef(null);
	const [phone, setPhone] = useState("");
	const [inputType, setInputType] = useState("number");
	const [callStatus, setCallStatus] = useState("");
	const [isOutsideCall, setIsOutsideCall] = useState(false);
	const user = useSelector(state => state.auth.user);
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

	const [isOpened, { toggle, handleClose }] = useDisclosure();
	const transferModal = useRef(null);
	const [transferedPhone, setTransferedPhone] = useState("");
	const [transferedPhoneType, setTransferedPhoneType] = useState("number");
	const [isOutsideCallMuted, setIsOutsideCallMuted] = useState(false);
	const [isIncomingCallMuted, setIsIncomingCallMuted] = useState(false);
	const [isHold, setIsHold] = useState(false);

	// focused input ref
	const focusedInputRef = useRef(null);

	// transfered call or not
	const [callFromRefer, setCallFromRefer] = useState(false);

	const { start, stop, reset, formattedTime } = useTimeTracker();

	const { ref, handleOpenMenu, handleCloseMenu } = useOutsideClick(
		false,
		null,
		false
	);

	// create listener for incoming call
	const { current: sipService } = useRef(
		new SipService({
			onConnecting: () => {
				setCallStatus("Соединение ...");
			},
			onRinging: () => {
				// setCallFromRefer(true);
				setCallStatus("Вызов ...");
				document.getElementById("ring-outgo-audio").muted = false;
				// setTimeout(function() {
				// 	document.getElementById("ring-outgo-audio").play();
				// }, 6000);
			},
			onAcceptCall: session => {
				setIsOutsideCall(true);
				handleOpenMenu();
				// console.log(session.remote_identity);
				setPhone(session.remote_identity.display_name);
				document.getElementById("ring-income-audio").muted = false;
				document.getElementById("ring-income-audio").play();
			},
			onAcceptReferCall: invitation => {
				setCallFromRefer(true);
			},
			onMute: () => {
				const audioIncoming = document.getElementById(
					"ring-income-audio"
				);
				audioIncoming.muted = true;
				audioIncoming.pause();
				setIsOutsideCallMuted(true);
			},
			toggleMuteIncomingCall: () => {
				setIsIncomingCallMuted(prev => !prev);
			},
			onToggleHold: isHold => {
				setIsHold(isHold);
			},
			onAnswered: () => {
				start();
				setCallStatus("В разговоре");
				setIsOutsideCall(prev => false);
				handleOpenMenu();
				document.body.setAttribute("class", "call_open");
				document.getElementById("ring-income-audio").pause();
				document.getElementById("ring-outgo-audio").pause();

				document.getElementById("ring-income-audio").muted = true;
				document.getElementById("ring-outgo-audio").muted = true;
			},
			onHangup: () => {
				stop();
				reset();
				document.getElementById("ring-income-audio").pause();
				document.getElementById("ring-outgo-audio").pause();

				document.getElementById("ring-income-audio").muted = true;
				document.getElementById("ring-outgo-audio").muted = true;

				document.getElementById("ring-income-audio").currentTime = 0;
				document.getElementById("ring-outgo-audio").currentTime = 0;
				const hangupAudio = document.getElementById("hang-up-audio");
				hangupAudio.play();
				hangupAudio.onended = () => {
					hangupAudio.currentTime = 0;
				};

				setCallStatus("");
				setIsHold(prev => false);
				setIsIncomingCallMuted(prev => false);
				setIsOutsideCallMuted(prev => false);
				setCallFromRefer(prev => false);
				setIsOutsideCall(prev => {
					if (prev) {
						handleCloseMenu();
					}
					return false;
				});
				setPhone("");
			}
		})
	);																																																																																																																																																																																																		

	const endCall = sipService.endCall.bind(sipService);
	const acceptCall = sipService.acceptCall.bind(sipService);
	const muteOutsideCall = sipService.muteOutsideCall.bind(sipService);
	const toggleHold = sipService.toggleHold.bind(sipService);
	const toggleMuteIncomingCall = sipService.toggleMuteIncomingCall.bind(
		sipService
	);

	const { handlers } = useLongPress(
		() => {
			if (focusedInputRef.current === inputRef.current) {
				setPhone(prev => prev.slice(0, -1));
				if (phone.length === 1) {
					setInputType(prev => "number");
				}
			} else if (focusedInputRef.current === transferModal.current) {
				setTransferedPhone(prev => prev.slice(0, -1));
				if (transferedPhone.length === 1) {
					setTransferedPhoneType(prev => "number");
				}
			}
		},
		() => {
			if (focusedInputRef.current === inputRef.current) {
				setPhone("");
				if (!phone.length) {
					setInputType(prev => "number");
				}
			} else if (focusedInputRef.current === transferModal.current) {
				setTransferedPhone("");
				if (!transferedPhone.length) {
					setTransferedPhoneType(prev => "number");
				}
			}
		}
	);

	const makeCall = () => {
		if (phone.length > 2 && phone.length < 12) {
			setCallStatus("Вызов ...");
			sipService.call(phone.replace(/\D/g, ""));
		}
	};

	const handleFocus = e => {
		focusedInputRef.current = e.target;
	};

	const writeNumberString = (character, _setPhone, _phone, _setInputType) => {
		if (character === "#" || character === "*") {
			_setInputType("text");
			_setPhone(prev => (prev + character).replace(/\s/g, ""));
		} else if (character === "C") {
			_setPhone("");
			_setInputType("number");
		} else {
			if (_phone.length !== 11) {
				_setPhone(prev => {
					if (prev.length === 2 || prev.length === 6) {
						return `${prev} ${character}`;
					}
					return `${prev}${character}`;
				});
			}
		}
	};

	const writeNumber = character => {
		if (focusedInputRef.current === inputRef.current) {
			writeNumberString(character, setPhone, phone, setInputType);
		} else if (focusedInputRef.current === transferModal.current) {
			writeNumberString(
				character,
				setTransferedPhone,
				transferedPhone,
				setTransferedPhoneType
			);
		}
	};

	const formatPhoneNumber = (e, setState) => {
		const input = e.target;
		// Raqamli bo'lmagan belgilarni olib tashlash
		const numericValue = input.value.replace(/[^0-9]/g, "");

		// 3 va 5-raqamlardan keyin bo'sh joy qo'yish
		let formattedValue = "";
		for (let i = 0; i < numericValue.length; i++) {
			if (i === 2 || i === 5) {
				formattedValue += " ";
			}
			formattedValue += numericValue[i];
		}
		setState(prev => formattedValue);
	};

	useEffect(() => {
		window.makeCallFromOutside = phone => {
			handleOpenMenu();
			setPhone(phone);
			setCallStatus("Загрузка ...");
			sipService.call(phone);
		};
	}, []);

	useEffect(() => {
		if (get(user, "operator_number")) {
			sipService.init(get(user, "operator_number"));
			window.sipService = sipService;
		}
	}, [user]);

	useEffect(() => {
		if (isOutsideCall) {
			document.body.setAttribute("class", "call_open");
		}
	}, [isOutsideCall]);

	useEffect(() => {
		const handleMutation = mutationsList => {
			for (const mutation of mutationsList) {
				if (
					mutation.type === "attributes" &&
					mutation.attributeName === "class"
				) {
					const bodyClass = document.body.classList.contains(
						"call_open"
					);
					if (bodyClass) {
						if (inputRef.current) inputRef.current.focus();
						setTransferedPhone("");
						setTransferedPhoneType("number");
						handleClose();
					}
				}
			}
		};

		const observer = new MutationObserver(handleMutation);
		observer.observe(document.body, { attributes: true });

		return () => observer.disconnect();
	}, []);

	const props = useOutsideClick(false, event => {
		const body = document.querySelector("body");
		if (
			body.className.includes("call_open") &&
			!event.target.closest("#call-button") &&
			!event.target.closest(".call-forward__modal") &&
			!event.target.closest(".modal.open")
		) {
			body.removeAttribute("class");
		}
	});

	const permission = async () => {
		const result = await window.checkPermission();
		const body = document.querySelector("body");

		if (!result && body.className.includes("call_open")) {
			body.removeAttribute("class");
		}
		return result;
	};

	const style = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "#f0f0f0",
		borderRadius: "10px",
		boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
	};

	return (
		<div className={"drop-down"} ref={ref}>
			<div className="drop-down__inner drop-down__call_window">
				{isOutsideCall ? (
					<div className="call__income">
						<p className="call__income-subtitle">Входящий вызов</p>
						<h3 className="call__income-caller">Клиент</h3>
						<p className="call__income-phone">{phone}</p>

						<div
							style={{
								gap: 20
							}}
							className="d-flex">
							<button
								id="take-call-btn"
								className="call-keyboard__call"
								onClick={acceptCall}>
								<img
									src={require("assets/images/svg/call-manual.svg")}
									alt=""
								/>
							</button>

							{!isOutsideCallMuted && (
								<button
									id="mute_btn"
									className="call-keyboard__mute ml-20"
									onClick={muteOutsideCall}>
									<img
										src={require("assets/images/svg/mute.svg")}
										alt=""
									/>
								</button>
							)}

							<button
								id="hangup-call-btn"
								className="call-keyboard__hangup ml_20"
								onClick={() => {
									endCall();
									setIsHold(prev => false);
								}}>
								<img
									src={require("assets/images/svg/hangup.svg")}
									alt=""
								/>
							</button>
						</div>
					</div>
				) : (
					<>
						<Rnd
							style={style}
							default={{
								x: 0,
								y: 0,
								width: 300
							}}>
							<form
								ref={props.ref}
								className="call-keyboard"
								onSubmit={event => {
									event.preventDefault();
									if (callStatus === "") {
										permission() && makeCall(event);
									} else {
										endCall();
										setIsHold(prev => false);
									}
								}}>
								<label className="d-flex align-items-center">
									{inputType === "number" ? (
										<span className="call-keyboard__label">
											+998
										</span>
									) : null}
									<input
										className="call-keyboard__phone"
										value={phone}
										// inputType
										type={"text"}
										// pattern="[0-9]{9}"
										ref={inputRef}
										onFocus={handleFocus}
										onChange={e =>
											formatPhoneNumber(e, setPhone)
										}
										maxLength={11}
									/>
									<button
										type="button"
										className="call-keyboard__backspace"
										{...handlers}>
										<img
											src={require("assets/images/svg/call-backspace.svg")}
											alt=""
										/>
									</button>
								</label>

								<span className="call-status">
									{callStatus === "В разговоре"
										? formattedTime
										: callStatus}
								</span>
								<div className="row g-3">
									<div className="col-4" />
									<div className="col-4">
										{callStatus.length > 0 && (
											<button
												type="button"
												className="call-keyboard__mute"
												onClick={() => toggleHold()}>
												<img
													src={
														isHold
															? require("assets/images/svg/mute-resume.svg")
															: require("assets/images/svg/mute-pause.svg")
													}
													alt=""
												/>
											</button>
										)}
									</div>
									<div className="col-4" />

									{phoneChars.map((item, index) => (
										<div className="col-4" key={index}>
											<button
												className="call-keyboard__number"
												type="button"
												onClick={() =>
													writeNumber(item)
												}>
												{item}
											</button>
										</div>
									))}

									<div className="col-4 call-forward">
										<button
											onClick={toggle}
											className={"call-keyboard__forward"}
											type={"button"}>
											<img
												src={require("assets/images/svg/call-forward-icon.svg")}
												alt=""
											/>
										</button>
									</div>

									<div className="col-4">
										<button
											className={
												callStatus === ""
													? "call-keyboard__call"
													: "call-keyboard__hangup"
											}
											id="manual-call-btn"
											disabled={
												callStatus === "Загрузка ..."
											}
											type="submit">
											<img
												src={require("assets/images/svg/call-manual.svg")}
												alt=""
											/>
										</button>
									</div>
									<div className="col-4">
										<button
											style={{
												backgroundColor: isIncomingCallMuted
													? "#B3BAC3"
													: "#052549",
												boxShadow: `0 8px 14px 0 ${
													isIncomingCallMuted
														? "#B3BAC340"
														: "#05254940"
												}`
											}}
											type="button"
											id="hold"
											className="call-keyboard__hold"
											onClick={() =>
												toggleMuteIncomingCall(
													isIncomingCallMuted
												)
											}>
											<img
												src={
													isIncomingCallMuted
														? require("assets/images/svg/hold.svg")
														: require("assets/images/svg/unhold.svg")
												}
												alt=""
											/>
										</button>
									</div>
								</div>
							</form>
						</Rnd>
						{isOpened && (
							<ForwardCall
								transferedPhoneType={transferedPhoneType}
								handleFocus={handleFocus}
								sipService={sipService}
								transferedPhone={transferedPhone}
								setTransferedPhone={setTransferedPhone}
								formatPhoneNumber={formatPhoneNumber}
								ref={transferModal}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
});
