import React, { useCallback, useMemo } from "react";
import { queryBuilder } from "services";
import config from "config";
import axios from "axios";
import { storage } from "services";
import { toast } from "react-toastify";

export const ButtonExport = ({ range, url, ...props }) => {
	const [isLoading, setIsLoading] = React.useState(false);

	const linkToFile = useMemo(() => queryBuilder(`${config.API_ROOT}${url}`), [
		range,
		url
	]);
	const downloadFile = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await axios.post(
				linkToFile,
				{
					from: range.from,
					to: range.to
				},
				{
					headers: {
						Authorization: `Bearer ${storage.get("token")}`
					}
				}
			);

			const downloadUrl = response.data.data.download_url;

			if (!downloadUrl) {
				throw new Error("Download URL not found");
			}

			window.open(downloadUrl, "_blank");

			setIsLoading(false);
		} catch (error) {
			toast.error("Fileni yuklab olishda xatolik yuz berdi!");
			console.log("Error donwload file", error);
			setIsLoading(false);
		} finally {
			setIsLoading(false);
		}
	}, [range, url]);

	return (
		<button
			onClick={() => downloadFile()}
			className="modal-btn d-flex export-type-btn"
			{...props}>
			<img src={require("assets/images/svg/export.svg")} height={18} alt="export" />
			{isLoading ? "Экспорт..." : "Экспорт"}
		</button>
	);
};

// <a  href={queryBuilder(`${config.API_ROOT}${url}`, {
// 			extra: range
// 		})} download={true} className="export-btn" {...props}>
// 			<img src={require("assets/images/svg/export.svg")} alt="export" />
// 			Export
// 		</a>
