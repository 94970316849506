import BarChart from "./BarChart";
import Donut from "./Donut";
import DonutThin from "./DonutThin";
import Line from "./Line";

export default {
    BarChart,
    Donut,
    DonutThin,
    Line
}