// import { get } from "lodash";

export const constants = {
	ESC_KEY_CODE: 13,

	STATUS_START: 1,
	STATUS_END: 2,
	STATUS_NO_ANSWER: 3,
	STATUS_SELF: 4,
	STATUS_IMPORT: 5,
	STATUS_CLIENT_BUSY: 6,
	STATUS_CLIENT_NO_ANSWER: 7,

	BOARD_LOCATION_LEAD: 1,
	BOARD_LOCATION_TRADE: 2,
	BOARD_LOCATION_TASK: 3,

	TASK_TYPE_LEAD: 1,
	TASK_TYPE_TRADE: 2,
	TASK_TYPE_TASK: 3,

	TYPE_AT_WORK: 1,
	TYPE_COFFEE_BREAK: 2,
	TYPE_AT_LUNCH: 3,
	TYPE_NOT_WORKING: 4,

	CONNECTED_TRUE: 1,
	CONNECTED_FALSE: 2,

	ROLE_MANAGER: 3,
	ROLE_ADMIN: 10,
	ROLE_OPERATOR: 2,

	TASK_TYPE_INCOMING_CALL: 1,
	TASK_TYPE_INCOMING_CALL_NO_ANSWER: 2,
	TASK_TYPE_OUTGO_CALL: 3,
	TASK_TYPE_OUTGO_CALL_BUSY: 4,
	TASK_TYPE_OUTGO_CALL_NO_ANSWER: 5,
	TASK_TYPE_MANUAL_CALL: 6,
	TASK_TYPE_CHANGED_ASSIGNED: 7,
	TASK_TYPE_CHAT_MESSAGE: 8,
	TASK_TYPE_ASSIGNED_NOTIFICATION: 9,
	TASK_TYPE_CHAT_FILE: 11,

	BOARD_STATUS_DELETED: -1,
	BOARD_STATUS_ACTIVE: 1,

	BOARD_TYPE_NORMAL: 0,
	BOARD_TYPE_START: 1,
	BOARD_TYPE_END: 2,

	TASK_ACTION_TYPE_NOTIFICATION: 1
};

constants.color = [
	"#4AA9F8",
	"#69D6D7",
	"#4A72F6",
	"#9B7DEB",
	"#E5638C",
	"#BF3436",
	"#F3AD3D",
	"#F8D451"
];

constants.taskActionTypes = [
	{
		label: "Напоминание",
		id: constants.TASK_ACTION_TYPE_NOTIFICATION
	}
];

constants.boardTypes = [
	{
		label: "Старт",
		id: constants.BOARD_TYPE_START
	},
	{
		label: "Конец",
		id: constants.BOARD_TYPE_END
	},
	{
		label: "Обычный",
		id: constants.BOARD_TYPE_NORMAL
	}
];

constants.statuses = [
	{
		label: "Входящий",
		id: constants.STATUS_END
	},
	{
		label: "Пропущенный",
		id: constants.STATUS_NO_ANSWER
	},
	{
		label: "Исходящий",
		id: constants.STATUS_SELF
	},
	{
		label: "Импортированный",
		id: constants.STATUS_IMPORT
	},
	{
		label: "Занят",
		id: constants.STATUS_CLIENT_BUSY
	},
	{
		label: "Не взял",
		id: constants.STATUS_CLIENT_NO_ANSWER
	}
];

constants.types = [
	{
		label: "В работе",
		id: constants.TYPE_AT_WORK
	},
	{
		label: "Кофе перемена",
		id: constants.TYPE_COFFEE_BREAK
	},
	{
		label: "Обед",
		id: constants.TYPE_AT_LUNCH
	},
	{
		label: "Не работает",
		id: constants.TYPE_NOT_WORKING
	}
];

constants.broadCastTypes = [
	{
		label: "Отправить всем",
		id: 1
	},
	{
		label: "Отправить только одному клиенту",
		id: 2
	},
];

constants.getType = type => constants.types.find(item => item.id === type);
