import React from "react";
import moment from "moment";

import { CallStatus } from "components";
import { helpers } from "services";

function CallHistory({ status, history }) {
	return (
		<div className="history-items">
			<div className="history-call">
				<div className="history-number">{history.id}</div>
				<div className="history-tel">
					{helpers.formatPhoneView(history.phone)}
				</div>
			</div>
			{/* <div className="history-operator">{`${history.operator_id}`}</div> */}
			<div
				style={{
					textAlign: "right",
					paddingRight: "10px"
				}}>
				{moment.unix(history.created_at).format("DD.MM.YYYY HH:mm:ss")}
			</div>
			<div className="history-icon">
				<div className="history-icon">
					<CallStatus status={status} />
				</div>
			</div>
		</div>
	);
}

export default CallHistory;
