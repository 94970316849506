import React, { useState } from "react";
import { useParams } from "react-router";
import get from "lodash/get";
import EntityContainer from "modules/entity/containers";
import { time } from "services";

const CustomerInfo = () => {
	const { customerId } = useParams();
	const [taskInfo, setTaskInfo] = useState();

	return (
		<>
			<EntityContainer.One
				entity="board"
				name="view"
				url={`/customer/${customerId}`}
				primaryKey="id"
				params={{
					include:
						"source,country,region,district"
				}}
				onSuccess={data => {
					setTaskInfo(get(data, "data"));
				}}>
				{() => (
					<>
						{get(taskInfo, "type") === 2 ? (
							<div className="customer-info">
								<div className="info-title">
									Информации о компании
								</div>
								<div className="row info-card d-flex">
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Логотип компании
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"logo",
												"-"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Наименование компании
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "name")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											STIR
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "name")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Наимонавание компании
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "name")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Регистрирующий орган
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"name",
												"Tuman (shahar) hokimiyati"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Дата
										</p>
										<p className="info-wrapper__data">
											{time.to(
												get(
													taskInfo,
													"birthdate"
												)
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Фоундер
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "name")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											THSHT
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "", "-")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											DBIBT
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "", "-")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Адресс
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "address", "-")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Электронная почта
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"email",
												"-"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Дата рождения
										</p>
										<p className="info-wrapper__data">
											{time.to(
												get(
													taskInfo,
													"birthdate"
												)
											)}
										</p>
									</div>
								</div>
							</div>
						) : (
							<div className="customer-info">
								<div className="info-title">
									Информации о клиенте
								</div>
								<div className="row info-card d-flex">
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Ф.И.О
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "name")}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Пол
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"gender"
											) === 1
												? "Мужчина"
												: "Женщина"}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Дата рождения
										</p>
										<p className="info-wrapper__data">
											{time.to(
												get(
													taskInfo,
													"birthdate",
													"Нет данных"
												)
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Страна
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"country.name",
												"Нет данных"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Область
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"region.name",
												"Нет данных"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Город / Район
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"district.name",
												"Нет данных"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Адрес
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"address",
												"Нет данных"
											)}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Заявитель
										</p>
										<p className="info-wrapper__data">
											{get(taskInfo, "type") === 2
												? "Юридическое лицо"
												: "Физическое лицо"}
										</p>
									</div>
									<div className="info-wrapper col-4">
										<p className="info-wrapper__name">
											Канал рекламы
										</p>
										<p className="info-wrapper__data">
											{get(
												taskInfo,
												"source.name.uz",
												"Нет данных"
											)}
										</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</EntityContainer.One>
		</>
	);
};

export default CustomerInfo;
