import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { components } from "react-select";
import AsyncPaginate from "react-select-async-paginate";
import get from "lodash/get";

import { api, queryBuilder } from "services";
import { ReactComponent as FiChevron } from "assets/images/svg/fi_chevron-down.svg";

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<FiChevron />
		</components.DropdownIndicator>
	);
};

const load = async (
	search,
	prevOptions,
	{ page },
	url,
	filterParams,
	loadOptionParams,
	loadOptionsKey,
	initialValue,
	customParams
) => {
	const keyIncludeExists = "include" in customParams;
	const keyFilterExists = "filter" in customParams;
	const { data } = await api.request.get(
		queryBuilder(url, {
			include: keyIncludeExists ? customParams.include : [],
			page,
			filter: filterParams(search),
			...loadOptionParams(
				search,
				keyFilterExists ? customParams.filter : ""
			)
		})
	);

	return {
		options: loadOptionsKey
			? typeof loadOptionsKey === "function"
				? [...initialValue, ...loadOptionsKey(data)]
				: [...initialValue, ...get(data, loadOptionsKey, [])]
			: data,
		hasMore:
			get(data, "meta.currentPage", 1) < get(data, "meta.pageCount", 1),
		additional: { page: get(data, "meta.currentPage", 1) + 1 }
	};
};

const AsyncSelect = ({
	customParams = {},
	suffix,
	disableOptions,
	className,
	onChange = () => {},
	label,
	initialValue = [],
	isMulti,
	loadOptionsKey,
	placeholder,
	options,
	field,
	optionLabel,
	key,
	optionValue,
	form: { errors, setFieldValue, setFieldTouched, touched },
	isSearchable,
	menuPlacement,
	loadOptionsUrl,
	loadOptionsParams = {},
	filterParams = () => ({}),
	setRegionId = () => {},
	isDisabled,
	value = "value",
	skip = false,
	...props
}) => {
	const classNames = cx(
		"field-container",
		touched[field.name] && errors[field.name] && "has-error",
		className
	);

	const handleChange = (option, skip = false) => {
		if (option && !skip) {
			setFieldValue(field.name, option);
			setRegionId(option.id);
		} else {
			setFieldValue(field.name, "");
			setRegionId(null);
		}
		onChange(option);
	};

	return (
		<div className={classNames}>
			<div>
				{label && <div>{label}</div>}
				<AsyncPaginate
					{...props}
					key={key}
					classNamePrefix="modal-select"
					id={field.name}
					name={field.name}
					debounceTimeout={300}
					onChange={option => handleChange(option, skip)}
					onBlur={() => setFieldTouched(field.name, true)}
					getValue={option => option[optionValue]}
					isDisabled={isDisabled}
					getOptionLabel={option =>
						typeof optionLabel === "function" ? (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between"
								}}
								dangerouslySetInnerHTML={{
									__html: optionLabel(option)
								}}
							/>
						) : (
							option[optionLabel]
						)
					}
					getOptionValue={option =>
						typeof optionValue === "function"
							? optionValue(option)
							: option[optionValue]
					}
					value={value ? field.value : ""}
					openMenuOnClick={true}
					openMenuOnFocus={true}
					closeMenuOnScroll={true}
					// men
					components={{
						IndicatorSeparator: () => null,
						DropdownIndicator
					}}
					styles={{
						dropdownIndicator: (base, state) => ({
							...base,
							transition: "transform .3s ease",
							transform:
								state.selectProps.menuIsOpen && "rotate(180deg)"
						}),
						menu: (base, state) => ({
							...base,
							height: state.selectProps.menuIsOpen ? "auto" : "0",
							transition: "all .6s ease",
							opacity: state.selectProps.menuIsOpen ? 1 : 0
						})
					}}
					additional={{ page: 1 }}
					loadOptions={async (search, prevOptions, { page }) => {
						return await load(
							search,
							prevOptions,
							{ page },
							loadOptionsUrl,
							filterParams,
							loadOptionsParams,
							loadOptionsKey,
							initialValue,
							customParams
						);
					}}
					isOptionDisabled={option =>
						disableOptions
							.reduce((prev, curr) => [...prev, curr.id], [])
							.includes(option.id)
					}
					{...{
						isMulti,
						options,
						placeholder,
						isSearchable,
						menuPlacement
					}}
					closeMenuOnSelect={!isMulti}
				/>
				{touched[field.name] && errors[field.name] && (
					<small className="form-error">{errors[field.name]}</small>
				)}
			</div>
		</div>
	);
};

AsyncSelect.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	isSearchable: PropTypes.bool,
	menuPlacement: PropTypes.string,
	loadOptionsKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
		.isRequired
};

AsyncSelect.defaultProps = {
	title: "",
	className: null,
	optionValue: "id",
	optionLabel: "title",
	isSearchable: false,
	menuPlacement: "bottom",
	disableOptions: [],
	loadOptionsKey: "data",
	placeholder: ""
};

export default AsyncSelect;
