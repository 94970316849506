import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import "pages/Task/styles/ApplicationView.scss";
import EntityContainer from "modules/entity/containers";
import get from "lodash/get";
import { toast } from "react-toastify";
import { ApplicationCard } from "pages/Task/components/ApplicationCard";
import { useModal } from "hooks/useModal";
import { TaskModal } from "pages/Task/components/TaskModal";
import { ApplicationCallHistoryCard } from "pages/Task/components/ApplicationCallHistoryCard";

import { NotificationModal } from "pages/Task/components/NotificationModal";
import { useOutsideClick } from "hooks/useOutsideClick";

import { ReactComponent as ChevronLeft } from "assets/images/svg/chevron-left-icon.svg";
import call from "assets/images/svg/call-manual.svg";
import callOutline from "assets/images/png/telephone.png";
import address from "assets/images/png/pin.png";
import userAvatar from "assets/images/png/user_avatar.jpg";
import CustomerInfo from "../Task/CustomerInfo";
const CustomerViewNew = taskLog => {
	const { customerId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState("customerInfo");

	const {
		ref,
		isVisible,
		handleCloseMenu,
		handleToggleVisibility
	} = useOutsideClick();
	const modalTrigger = useSelector(state => get(state, "modal.refetch"));
	const taskModal = useModal();
	const [taskInfo, setTaskInfo] = useState();
	const [refetch, setRefetch] = useState(0);
	const [data, setData] = useState();
	const [callType, setCallType] = useState(1);
	const [formData, setFormData] = useState(null);

	console.log(taskLog);

	const renderContent = () => {
		switch (activeTab) {
			case "customerInfo":
				return <CustomerInfo {...{ callType, setCallType }} />;
			case "allApplications":
				return <ApplicationCard taskInfo={taskInfo} />;
			case "allCalls":
				return <ApplicationCallHistoryCard taskInfo={taskInfo} />;
			default:
				return null;
		}
	};

	return (
		<>
			<EntityContainer.One
				entity="board"
				name="view"
				url={`/customer/${customerId}`}
				primaryKey="id"
				params={{
					modalTrigger,
					include:
						// "customer,board,assigned,progress,customer.source,customer.region,customer.district"
						"source,region,district"
				}}
				fields={callType}
				onSuccess={data => {
					setTaskInfo(get(data, "data"));
				}}>
				{() => (
					<div className="main sec-padding">
						<div className="app-view">
							<TaskModal
								isOpen={taskModal.isOpen}
								handleClose={taskModal.handleClose}
								boardId={get(taskInfo, "board_id")}
								formData={taskInfo}
								isUpdate={customerId}
							/>
							<div className="app-view__info col-4">
								<div className={"info-inner-wrapper"}>
									<a
										className="app-view__info-back"
										href="/application">
										<ChevronLeft />
										Информация о заявителе
									</a>
									<div className="app-view__info-application">
										<div
											className="d-flex flex-column align-items-center"
											style={{ gap: "10px" }}>
											<div className="app-view__info-image">
												{get(taskInfo, "image") ? (
													get(taskInfo, "image")
												) : (
													<img src={userAvatar} alt="" width={120} />
												)}
											</div>
											<div className="app-view__info-name">
												{get(taskInfo, "name")}
											</div>
											<div className="app-view__info-type">
												{get(taskInfo, "type") === 1
													? "Физическое лицо"
													: "Юридическое лицо"}
											</div>
											<button
												className="app-view__info-btn"
												onClick={() => {
													if (
														get(
															taskInfo,
															"phone",
															""
														)
													) {
														window.makeCallFromOutside(
															String(
																get(
																	taskInfo,
																	"phone",
																	""
																)
															).replace(/\D/g, "")
														);
													} else {
														toast.error(
															"Номер отсутствует",
															{
																autoClose: 5000
															}
														);
													}
												}}>
												<img
													className="app-view__info-img"
													src={call}
													alt="call"
												/>
												Позвонить
											</button>
										</div>
									</div>

									<div className="app-view__info-content">
										<div className="app-view__info-user">
											<p className="app-view__info-subtext">
												Информация о звонках
											</p>
											<div className="app-view__info-item d-flex align-items-center m-t-20">
												<div className="app-view__info-call">
													<div
														className="info-call border-bottom d-flex justify-content-between">
														<p className="info-call__name">
															Количество звонков:
														</p>
														<p className="info-call__number">
															123
														</p>
														<p className="info-call__progress">
															123
														</p>
													</div>
													<div
														className="info-call border-bottom d-flex justify-content-between">
														<p className="info-call__name">
															Входящие:
														</p>
														<p className="info-call__number"></p>
														<p className="info-call__progress"></p>
													</div>
													<div
														className="info-call border-bottom d-flex justify-content-between">
														<p className="info-call__name">
															Пропущенные:
														</p>
														<p className="info-call__number"></p>
														<p className="info-call__progress"></p>
													</div>
													<div
														className="info-call border-bottom d-flex justify-content-between">
														<p className="info-call__name">
															Исходящий:
														</p>
														<p className="info-call__number"></p>
														<p className="info-call__progress"></p>
													</div>
													<div
														className="info-call border-bottom d-flex justify-content-between">
														<p className="info-call__name">
															Абонент занят:
														</p>
														<p className="info-call__number"></p>
														<p className="info-call__progress"></p>
													</div>
													<div className="info-call d-flex justify-content-between">
														<p className="info-call__name">
															Не поднял трубку:
														</p>
														<p className="info-call__number"></p>
														<p className="info-call__progress"></p>
													</div>
												</div>
											</div>
											<p
												className="app-view__info-subtext"
												style={{ marginTop: "20px" }}>
												Контактная информация
											</p>
											<div className="app-view__info-bottom">
												<div
													className="d-flex align-items-center justify-content-between app-view__info-item-top">
													<div className="app-view__info-contact">
														<div className="app-view__info-card d-flex align-items-center">
															<div className="image">
																<img
																	src={callOutline}
																	alt="call"
																/>
															</div>
															<div className="info">
																<p>
																	Основной номер
																	телефона
																</p>
																<p
																	style={{
																		color: "#000"
																	}}>
																	{get(
																		taskInfo,
																		"phone"
																	)}
																</p>
															</div>
														</div>
														<div className="app-view__info-card d-flex align-items-center">
															<div className="image">
																<img
																	src={callOutline}
																	alt="call"
																/>
															</div>
															<div className="info">
																<p>
																	Дополнительный номер
																	телефона
																</p>
																<p
																	style={{
																		color: "#000"
																	}}>
																	{get(
																		taskInfo,
																		"additional_number.telegram_number",
																		"Отсутствует"
																	)}
																</p>
															</div>
														</div>
														<div className="app-view__info-card d-flex align-items-center">
															<div className="image">
																<img
																	src={address}
																	alt="address"
																/>
															</div>
															<div className="info">
																<p>Адрес</p>
																<p
																	style={{
																		color: "#000"
																	}}>
																	{get(
																		taskInfo,
																		"district.name",
																		"Нет данных"
																	)}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="app-view__content col-8">
								<div className="app-view__navbar">
									<div
										className={`navbar-item ${
											activeTab === "customerInfo"
												? "active"
												: ""
										}`}
										onClick={() =>
											setActiveTab("customerInfo")
										}>
										Информация о клиенте
									</div>
									<div
										className={`navbar-item ${
											activeTab === "allApplications"
												? "active"
												: ""
										}`}
										onClick={() =>
											setActiveTab("allApplications")
										}>
										Все заявки
									</div>
									<div
										className={`navbar-item ${
											activeTab === "allCalls" ? "active" : ""
										}`}
										onClick={() => setActiveTab("allCalls")}>
										Все вызовы
									</div>
								</div>
								{renderContent()}
							</div>
						</div>
					</div>
				)}
			</EntityContainer.One>
		</>
	);
};

export default CustomerViewNew;
