import React from "react";
// import EntityForm from "modules/entity/forms";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import service from "../../../../assets/images/svg/service.svg";
import organizationNum from "../../../../assets/images/svg/organizationNum.svg";
import source from "../../../../assets/images/svg/source.svg";
import callHistory from "../../../../assets/images/svg/callHistory.svg";
import statistic from "../../../../assets/images/svg/statistic.svg";
import operators from "../../../../assets/images/svg/operators.svg";
import plugins from "../../../../assets/images/svg/plugins.svg";
import serviceInfo from "../../../../assets/images/svg/service-info.svg";
import dots from "../../../../assets/images/svg/dots.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/svg/close.svg";
import { useSelector } from "react-redux";

const SettingsModal = ({ handleClose }) => {
	const { user } = useSelector(state => state.auth);

	return (
		<div
			style={{
				maxWidth: 800
			}}
			className="modal-wrapper">
			<div className="settingsModall">
				<p className="settingsModall__text">Расширенные настройки</p>
				<nav>
					<ul
						style={{
							maxHeight: "unset"
						}}
						className="settingsModall__list">
						<li className="settingsModall__item">
							<Link
								to="/operator-statistics"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={service} alt="" />
								<p>Операторы</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/analytice"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={organizationNum} alt="" />
								<p>Аналитика</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/profile"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={source} alt="" />
								<p>Профиль</p>
							</Link>
						</li>
						<li className="settingsModall__item">
							<Link
								to="/organization-number"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={callHistory} alt="" />
								<p>Номер</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/service"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={statistic} alt="" />
								<p>Услуги</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/source"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={operators} alt="" />
								<p>Источники</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/plugins"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={plugins} alt="" />
								<p>Плагины</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/reasons"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={serviceInfo} alt="" />
								<p>Причины</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to={`/pbx/auth/${user.token}`}
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={dots} alt="" />
								<p>PBX</p>
							</Link>
						</li>

						<li className="settingsModall__item">
							<Link
								to="/category"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={statistic} alt="" />
								<p>Категория</p>
							</Link>
						</li>
						<li className="settingsModall__item">
							<Link
								to="/sub-category"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={statistic} alt="" />
								<p>Подкатегория</p>
							</Link>
						</li>
						<li className="settingsModall__item">
							<Link
								to="/currency"
								className="settingsModall__link"
								onClick={() => {
									handleClose && handleClose();
								}}>
								<img src={statistic} alt="" />
								<p>Валюта</p>
							</Link>
						</li>
					</ul>
				</nav>

				<button
					type="reset"
					className="settingsModall__close"
					data-bs-dismiss="modal"
					onClick={() => {
						handleClose && handleClose();
						// toast.error("Действие отменено!")
					}}>
					<CloseIcon />
				</button>
			</div>
		</div>
	);
};

export default SettingsModal;
